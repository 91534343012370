import { Card, CardContent, CardHeader, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import FacebookLogoButton from '../../components/FacebookLogoButton';
import MainLogoAvatar from '../../MainLogoAvatar';

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    marginBottom: theme.spacing(4),
  },
  contactCardHeader: {
    backgroundColor: theme.palette.primary.light,
  },
  contactCardContent: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  icon: {
    fontSize: '3rem',
  },
  gridItem: {
    marginBottom: theme.spacing(3),
  },
  contactOption: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  free15Minute: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const ContactDetails = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3} justify="center">
        <Grid className={classes.gridItem} item xs={12} md={9}>
          <Card raised>
            <CardHeader
              className={classes.contactCardHeader}
              title={<Typography variant="h4">Get in touch</Typography>}
              avatar={<MainLogoAvatar />}
            />
            <CardContent className={classes.contactCardContent}>
              <Typography variant="h6">
                <p>
                  To book one of my packages or to organise a <span className={classes.free15Minute}>free 15 minute chat</span> about how I can help,
                  please get in touch.
                </p>
              </Typography>
              <Grid className={classes.contactOption} container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">Email</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link href="mailto:help@sweetdreamsjellybean.com.au">help@sweetdreamsjellybean.com.au</Link>
                </Grid>
              </Grid>
              <Grid className={classes.contactOption} container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">Phone</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link href="tel:0412084632">0412 084 632</Link>
                </Grid>
              </Grid>
              <Grid className={classes.contactOption} container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">Facebook</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FacebookLogoButton large />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactDetails;
