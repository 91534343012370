import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Contact from '../containers/Contact';

const ContactPage = () => {
  return (
    <Layout>
      <SEO
        title="Contact"
        description="Contact information for Jenny-Lee Fyfe, founder of Sweet Dreams Jellybean. Certified sleep consultant based in Bayside, Melbourne, Victoria.>"
      />
      <Contact />
    </Layout>
  );
};

export default ContactPage;
