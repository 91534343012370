import { ThemeProvider } from '@material-ui/styles';
import * as React from 'react';
import materialTheme from '../../theme/material-theme';
import ContactDetails from './ContactDetails';
import { ContactWrapper } from './style';

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  return (
    <ThemeProvider theme={materialTheme}>
      <ContactWrapper>
        <ContactDetails />
      </ContactWrapper>
    </ThemeProvider>
  );
};

export default About;
